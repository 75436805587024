<template>
  <div class="page">
    <div v-if="!isMobile" class="banner">
      <img src="@client/assets/images/banner.jpg">
    </div>
    <div
      :class="[
        'apply-wrapper',
        isMobile ? 'apply-wrapper-mobile' : 'apply-wrapper-desktop'
      ]"
    >
      <div v-if="!isMobile" class="title">Indra应聘登记表</div>
      <!-- v-loading="loading" -->
      <div class="apply-forms-wrapper" id="applyFormsWrapper">
        <div v-show="!isShowResult" class="forms-wrapper">
          <ApplyForm
            ref="applyForm"
            :form="params"
            :isDisable="isViewForm"
            :isMobile="isMobile"
          />
          <PersonForm
            ref="personForm"
            :form="params"
            :isDisable="isViewForm"
            :isMobile="isMobile"
          />
          <ContactForm
            ref="contactForm"
            :form="params"
            :isDisable="isViewForm"
            :isMobile="isMobile"
          />
          <EducationForm
            ref="educationForm"
            :formModel="eductionFormModel"
            :isDisable="isViewForm"
            :isMobile="isMobile"
          />
          <ExperienceForm
            ref="experienceForm"
            :formModel="experienceFormModel"
            :isDisable="isViewForm"
            :isMobile="isMobile"
          />
        </div>
        <div v-show="!isShowResult" class="bottom-wrapper">
          <div class="tips-wrapper">
            <p class="tips">提交申请则默认同意以下用户声明：</p>
            <p class="txt">
              1、本人许可并授权贵公司在发放录用通知书前，可通过背景调查等方式对本人在应聘登记表和应聘简历中所提供信息的真实性和完整性进行调查。
            </p>
            <p class="txt">
              2、本人在应聘登记表和应聘简历中所提供的各项信息均真实、有效，如提供的信息、相关证明文件等不属实或有误导，可视为本人违反诚信原则，本人的应聘申请可视为取消，如本人已受聘，上述行为亦将被视为严重违纪，本人将接受公司以严重违纪理由立即解除劳动合同，且不给予任何经济补偿或赔偿。
            </p>
          </div>
          <div class="btns-wrapper">
            <!-- 首次提交 v-if="!isViewForm"-->
            <el-button
              v-if="!isViewForm && !isReSubmit"
              class="btn"
              type="primary"
              size="medium"
              :loading="loadingSubmit"
              :disabled="loading"
              @click="applySubmit"
            >
              {{ loadingSubmit ? '提交中' : '提交申请' }}</el-button
            >
            <!-- 查看记录 isViewForm-->
            <el-button
              v-if="isViewForm"
              class="btn"
              size="medium"
              @click="reEdit"
            >
              重新编辑</el-button
            >
            <!-- 重新编辑 -->
            <template v-if="isReSubmit">
              <el-button class="btn" size="medium" @click="cancelSubmit">
                取消</el-button
              >
              <el-button
                type="primary"
                class="btn"
                size="medium"
                @click="reApplySubmit"
              >
                重新提交</el-button
              >
            </template>
          </div>
        </div>
        <!-- 提交后 -->
        <ApplyResult
          class="result-wrapper"
          :isMobile="isMobile"
          v-show="isShowResult"
          @view="checkResult"
          @edit="reEdit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ApplyForm,
  ContactForm,
  EducationForm,
  ExperienceForm,
  PersonForm,
  ApplyResult
} from '@client/components/job-comps'
import { createResume } from '@client/api/base/modules/job'
import { mapState } from 'vuex'
import { confirm } from '@/utils/messageBox'
import { getQuery } from '@/utils/query'
import dayjs from 'dayjs'

export default {
  name: 'JobIndex',
  components: {
    ApplyForm,
    PersonForm,
    ContactForm,
    EducationForm,
    ExperienceForm,
    ApplyResult
  },
  data() {
    return {
      loading: false,
      loadingSubmit: false,
      isShowResult: false, // 是否显示查看记录
      isViewForm: false, // 是否查看记录，true禁用表单
      isReSubmit: false, // 是否重新提交
      params: {
        // applyForm
        job_name: '',
        join_time: '',
        current_salary: '',
        expected_salary: '',
        current_jon_status: '',
        origin: '', // 渠道来源
        channel: [], // 自行投递-招聘信息 接口传3,7
        other_channel: '', // 其他渠道
        referee: '', // 推荐人
        // personForm
        name: '',
        sex: '',
        birth_date: '',
        nation: '',
        native_place: '',
        political_status: '',
        is_sign_agreement: '',
        // contactForm
        phone: '',
        email: '',
        wechat: '',
        urgent_phone: '',
        home_place: ''
      },
      // 教育
      eductionFormModel: {
        tableData: [
          {
            education_type: '',
            education_name: '',
            is_fulltime: '',
            start_time: '',
            end_time: '',
            academic_degree: '',
            course: ''
          }
        ],
        rules: {
          education_type: [
            { required: true, message: '请选择', trigger: 'change' }
          ],
          education_name: [
            { required: true, message: '请输入', trigger: 'blur' }
          ],
          is_fulltime: [
            { required: true, message: '请选择', trigger: 'change' }
          ],
          start_time: [
            // { required: true, message: '请选择', trigger: 'change' },
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                this.eduTimesValidator(rule, value, callback, 'start')
              }
            }
          ],
          end_time: [
            // { required: true, message: '请选择', trigger: 'change' },
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                this.eduTimesValidator(rule, value, callback, 'end')
              }
            }
          ],
          academic_degree: [
            { required: true, message: '请输入', trigger: 'blur' }
          ],
          course: [{ required: true, message: '请输入', trigger: 'blur' }]
        }
      },
      experienceFormModel: {
        // tableData: [
        //   {
        //     company_name: '公司1',
        //     start_date: '2021-10-01 00:00:00',
        //     end_date: '2021-10-31 23:59:59',
        //     department: '部门1',
        //     job_title: '小职员',
        //     leave_reason: '原因1',
        //     salary: '1500',
        //     auth_phone: 'A经理；13838383388'
        //   },
        //   {
        //     company_name: '公司2',
        //     start_date: '2021-11-01 00:00:00',
        //     end_date: '2021-11-30 23:59:59',
        //     department: '部门2',
        //     job_title: '大经理',
        //     leave_reason: '原因2',
        //     salary: '6666',
        //     auth_phone: 'B总裁；13737373377'
        //   }
        // ],
        tableData: [
          {
            company_name: '',
            start_date: '',
            end_date: '',
            department: '',
            job_title: '',
            leave_reason: '',
            salary: '',
            auth_phone: ''
          },
          {
            company_name: '',
            start_date: '',
            end_date: '',
            department: '',
            job_title: '',
            leave_reason: '',
            salary: '',
            auth_phone: ''
          },
          {
            company_name: '',
            start_date: '',
            end_date: '',
            department: '',
            job_title: '',
            leave_reason: '',
            salary: '',
            auth_phone: ''
          }
        ],
        rules: {
          company_name: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请输入')
              }
            }
          ],
          start_date: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请选择开始年月')
              }
            },
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                this.expTimesValidator(rule, value, callback, 'start')
              }
            }
          ],
          end_date: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请选择结束年月')
              }
            },
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                this.expTimesValidator(rule, value, callback, 'end')
              }
            }
          ],
          department: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请输入')
              }
            }
          ],
          job_title: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请输入')
              }
            }
          ],
          leave_reason: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请输入')
              }
            }
          ],
          salary: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请输入')
              }
            }
          ],
          auth_phone: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请输入')
              }
            }
          ]
        }
      }
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.app.device
    }),
    isMobile() {
      return this.device === 'mobile'
    }
  },
  methods: {
    eduTimesValidator(rule, value, callback, type) {
      const arr = rule.field.split('.')
      const obj = this.eductionFormModel.tableData[+arr[1]]
      if (!obj.start_time) {
        callback(
          new Error('请选择开始年月'
          )
        )
      } else if (!obj.end_time) {
        callback(
          new Error('请选择结束年月'
          )
        )
      } else if (dayjs(obj.start_time).valueOf() > dayjs(obj.end_time).valueOf()) {
        callback(
          new Error(
              `${
                type === 'start'
                  ? '开始时间不可大于结束时间'
                  : '结束时间不可小于于开始时间'
              }`
          )
        )
      } else {
        callback()
      }
    },
    expTimesValidator(rule, value, callback, type) {
      const arr = rule.field.split('.')
      const obj = this.experienceFormModel.tableData[+arr[1]]
      if (
        (obj.company_name ||
          obj.start_date ||
          obj.end_date ||
          obj.department ||
          obj.job_title ||
          obj.leave_reason ||
          obj.salary ||
          obj.auth_phone) &&
        value !== ''
      ) {
        if (!obj.start_date) {
          callback(
            new Error('请选择开始年月'
            )
          )
        } else if (!obj.end_date) {
          callback(
            new Error('请选择结束年月'
            )
          )
        } else if (dayjs(obj.start_date).valueOf() > dayjs(obj.end_date).valueOf()) {
          callback(
            new Error(
              `${
                type === 'start'
                  ? '开始时间不可大于结束时间'
                  : '结束时间不可小于于开始时间'
              }`
            )
          )
        } else {
          callback()
        }
      } else {
        callback()
      }
    },
    expRulesValidator(rule, value, callback, msg) {
      const arr = rule.field.split('.')
      const obj = this.experienceFormModel.tableData[+arr[1]]
      if (
        (obj.company_name ||
          obj.start_date ||
          obj.end_date ||
          obj.department ||
          obj.job_title ||
          obj.leave_reason ||
          obj.salary ||
          obj.auth_phone) &&
        value === ''
      ) {
        callback(new Error(msg))
      } else {
        callback()
      }
    },
    async validate() {
      return await Promise.all([
        this.$refs.applyForm.validate(),
        this.$refs.personForm.validate(),
        this.$refs.contactForm.validate(),
        this.$refs.educationForm.validate(),
        this.$refs.experienceForm.validate()
      ])
    },
    getParams() {
      return this.validate()
        .then(async () => {
          // 获取请求参数
          const p1 = await this.$refs.applyForm.getData()
          const p2 = await this.$refs.personForm.getData()
          const p3 = await this.$refs.contactForm.getData()
          const p4 = await this.$refs.educationForm.getData()
          const p5 = await this.$refs.experienceForm.getData()
          const type = Number(getQuery('type') || 0) // 申请来源：官网=1；HR推荐=2；
          const params = {
            ...p1,
            ...p2,
            ...p3,
            eduction: p4,
            experience: p5,
            type: type === 1 ? 1 : 2
          }
          console.log('参数', params, 'p4', p4, 'p5', p5)
          return params
        })
        .catch((err) => {
          this.$message.error('还有字段尚未填写，请填写完整后再提交')
          const errorTracingPoint =
            document
              .querySelector('.el-form-item.is-error')
              .getBoundingClientRect().top + document.scrollingElement.scrollTop
          if (!this.isMobile) {
            document.scrollingElement.scrollTop =
              errorTracingPoint - (69 + 22 + 240)
          } else {
            document.scrollingElement.scrollTop = errorTracingPoint - 24
          }
          console.log(err)
        })
    },
    // 首次填写表单-提交按钮
    async applySubmit() {
      try {
        const params = await this.getParams()
        // 处理channel
        params?.channel.length > 0
          ? (params.channel = params?.channel.join(','))
          : (params.channel = '')
          // 处理教育背景
        const filterEduData = params?.eduction.map((i) => {
          return {
            ...i,
            start_time: dayjs(i.start_time).format('YYYY-MM-DD HH:mm:ss'),
            end_time: dayjs(i.end_time).format('YYYY-MM-DD HH:mm:ss')
          }
        })
        params.eduction = filterEduData
        // 处理工作经历
        const filterData = params?.experience.filter((item) => {
          return item.company_name !== ''
        }).map((i) => {
          return {
            ...i,
            is_now: i.end_date === '至今' ? 1 : 0,
            start_date: dayjs(i.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date: i.end_date === '至今' ? '至今' : dayjs(i.end_date).format('YYYY-MM-DD HH:mm:ss')
          }
        })
        params.experience = filterData
        console.log('createResume', params)
        this.loadingSubmit = true
        this.loading = true
        // 发起请求
        await createResume(params)
          .then((res) => {
            // 成功则
            this.$message({
              message: '提交成功',
              type: 'success',
              duration: 1000
            })
            // 缓存转格式
            const localParams = JSON.parse(JSON.stringify(params))
            localParams.eduction = localParams.eduction.map((i) => {
              return {
                start_time: dayjs(i.start_time).format('YYYY/MM/DD HH:mm:ss'),
                end_time: dayjs(i.end_time).format('YYYY/MM/DD HH:mm:ss')
              }
            })
            localParams.experience = localParams.experience.map((i) => {
              return {
                start_date: dayjs(i.start_date).format('YYYY/MM/DD HH:mm:ss'),
                end_date:
                i.is_now === 1
                  ? '至今'
                  : dayjs(i.end_date).format('YYYY/MM/DD HH:mm:ss')
              }
            })
            console.log('缓存转格式', localParams)
            localStorage.setItem('apply_form_params', JSON.stringify(localParams))
            setTimeout(() => {
              this.isShowResult = true
              this.loadingSubmit = false
              this.loading = false
            }, 1500)
          })
          .catch((err) => {
            console.log(err)
            // 失败则提示
            this.loadingSubmit = false
            this.loading = false
          })
      } catch (err) {
        console.log(err)
        this.loadingSubmit = false
      }
    },
    // 重新编辑-表单禁用的状态
    reEdit() {
      this.checkResult()
      this.isViewForm = false
      this.isReSubmit = true
    },
    // 取消-表单可编辑的状态
    cancelSubmit() {
      const tempOld = JSON.parse(localStorage.getItem('apply_form_params'))
      console.log('旧数据', tempOld)
      // 返回之前的值
      this.params = tempOld
      this.eductionFormModel.tableData = tempOld?.eduction
      this.experienceFormModel.tableData = tempOld?.experience
      this.isViewForm = true
      this.isReSubmit = false
      // localStorage.removeItem('apply_form_params')
    },
    // 重新提交-表单可编辑的状态
    reApplySubmit() {
      confirm({
        message: '确定要重新提交吗？',
        subMessage: '重新提交后，会覆盖之前填写的记录。',
        forever: false
      }).then(() => {
        this.isReSubmit = false
        this.applySubmit()
      })
    },
    // 查看记录-无表单
    checkResult() {
      this.isShowResult = false
      this.isViewForm = true
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  .banner {
    width: 100%;
    // height: 240px;
    // background-image: url('~@client/assets/images/banner.jpg');
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
        overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
     img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  // 桌面端
  .apply-wrapper-desktop {
    margin: auto;
    max-width: 1200px;
    .title {
      color: #223345;
      font-size: 24px;
      margin: 32px auto 0;
      text-align: center;
      font-weight: 500;
    }
    .apply-forms-wrapper {
      background-color: #ffffff;
      box-shadow: 0px 5px 20px 0px rgba(176, 195, 211, 0.16);
      border-radius: 8px;
      margin: 32px auto;
      .forms-wrapper {
        padding: 32px;
        ::v-deep {
          .el-form {
            &.el-form--label-top {
              .el-form-item__label {
                padding: 0;
                line-height: 25px;
              }
            }
            .el-form-item {
              width: 248px;
              margin-right: 48px;
              margin-bottom: 20px;
              &:nth-child(4n) {
                margin-right: 0;
              }
              &.one-line {
                width: 100%;
              }
              &.home-place-item{
                width: 544px;
              }
            }
            &.form {
              margin-top: 20px;
              margin-bottom: 12px;
              //&.exp-form ,
              &.edu-form {
                .el-form-item {
                  margin-bottom: 8px;
                }
                .el-form-item__error {
                  position: relative;
                  text-align: left;
                }
              }
            }
            .el-table {
              .el-form-item {
                width: 100%;
              }
            }
            .channel-group {
              .el-form-item {
                width: auto;
                margin-right: 0;
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .bottom-wrapper {
        .tips-wrapper {
          border-top: rgba(0, 0, 0, 0.05) 1px solid;
          padding: 32px 35px 0;
          font-size: 14px;
          p {
            margin-block-start: 5px;
            margin-block-end: 5px;
          }
          .tips {
            font-weight: bold;
            color: #223345;
            margin-bottom: 8px;
          }
          .txt {
            color: #8c94a5;
            line-height: 25px;
          }
        }
        .btns-wrapper {
          text-align: center;
          padding: 48px 0 32px;
        }
      }
    }
    .result-wrapper {
      min-height: 582px;
    }
  }
  // 移动端
  .apply-wrapper-mobile {
    margin: auto;
    .apply-forms-wrapper {
      background-color: #ffffff;
      margin-top: 12px;
      .forms-wrapper {
        padding: 12px;
        ::v-deep {
          .el-form {
            padding-top: 16px;
            .el-form-item__label {
              padding: 0;
              line-height: 25px;
            }
            .el-form-item__content {
              line-height: 20px;
            }
            .el-form-item {
              width: 100%;
              margin-right: 0;
              margin-bottom: 20px;
              &.channel-form-item {
                margin-bottom: 0;
              }

              &:nth-child(4n) {
                margin-right: 0;
              }
              &.one-line {
                width: 100%;
              }
            }
            .el-table {
              .el-form-item {
                width: 100%;
              }
            }
          }
          .pick-time {
            width: 100%;
          }
          .el-radio-group {
            line-height: 25px;
          }
        }
      }
      .bottom-wrapper {
        .tips-wrapper {
          border-top: rgba(0, 0, 0, 0.05) 1px solid;
          padding: 12px;
          font-size: 14px;
          line-height: 20px;
          p {
            margin-block-start: 5px;
            margin-block-end: 5px;
          }
          .tips {
            font-weight: bold;
            color: #223345;
            margin-bottom: 8px;
          }
          .txt {
            color: #8c94a5;
          }
        }
        .btns-wrapper {
          border-top: 1px solid rgba(0, 0, 0, 0.05);
          text-align: center;
          padding: 24px 12px;
          .btn {
            display: inline-block;
            width: 48%;
          }
        }
      }
    }
    .result-wrapper {
      height: calc(100vh - 69px);
      ::v-deep {
        .success-img-wrap {
          .success-img {
            width: 150px;
            height: 100px;
          }
        }
        .txt {
          font-size: 16px;
          font-weight: 600;
        }
        .btns {
          padding: 0 12px;
          .btn {
            display: block;
            width: 100%;
            margin: 0 0 12px;
          }
        }
      }
    }
  }
  ::v-deep {
    .el-input.is-disabled .el-input__inner,
    .el-range-editor.is-disabled input {
      opacity: 1;
      color: #8c94a5;
      -webkit-text-fill-color: #8c94a5;
    }
  }
  @media screen and (max-width: 1200px) {
    .apply-wrapper-desktop {
      .forms-wrapper {
        ::v-deep {
          .el-form {
            .el-form-item {
              margin-right: 48px !important;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 750px) {
  .el-message-box {
    width: 90% !important;
  }
}
</style>
