/*
 * @Author: Qy
 * @Date: 2021/10/20 16:57
 * @LastEditTime: 2021/10/25 14:53
 * @LastEditors: Qy
 * @Description:职位申请
 */
import request from '@client/api/base/request'

export function createResume(data) { // 角色创建接口
  return request({
    url: '/other-resume',
    method: 'post',
    data
  })
}

// 后台
// 获取简历信息列表(分页)
export function getResumeList(params) {
  return request({
    url: '/rs-list',
    method: 'get',
    params
  })
}
// 详情接口
export function getResumeInfo(id, params) {
  return request({
    url: `/rs-list/${id}`,
    method: 'get',
    params
  })
}
// 删除接口
export function delResume(params) {
  return request({
    url: '/rs-del',
    method: 'delete',
    params
  })
}
// 获取多个简历信息,打印用
export function getResumeInfos(params) {
  return request({
    url: '/rs-infos',
    method: 'get',
    params
  })
}
// 简历信息导出
export function exportResume(data) { // 导出接口
  return request({
    url: '/rs-excel',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
